import React, { PureComponent } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

import './footer.scss';

class Footer extends PureComponent {
  render() {
    const { me } = this.props;
    return (
      <footer className="footer">
        <div className="footer__inner">
          <div className="page__grid">
            <div className="footer__left">
              <div className="footer__logo">
                <img src="/static/logo-no-text.svg" alt="StartHawk" />
              </div>
              <div className="footer__contact">
                <h3>Say Hello:</h3>
                <a href="mailto:hello@starthawk.io">hello@starthawk.io</a>
              </div>
              <div className="footer__social">
                <h3>Follow us:</h3>
                <ul>
                  <li>
                    <a href="https://www.facebook.com/starthawk/" target="_blank">
                      <img src="/static/social/facebook.svg" alt="Facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/14852139/" target="_blank">
                      <img src="/static/social/linkedin.svg" alt="Linkedin" />
                    </a>
                  </li>         
                  {/* <li>
                    <a href="https://twitter.com/Startbee2" target="_blank">
                      <img src="/static/social/twitter.svg" alt="Twitter" />
                    </a>
                  </li>  */}                                                
                </ul>
              </div>
            </div>
            <div className="footer__right">
              <ul>
                {!me && (
                  <li>
                    <Link href={'/'} >
                      <a>
                        Home
                      </a>
                    </Link>
                  </li>
                )}
                <li>
                  <Link href={'/find-a-co-founder'} >
                    <a>
                      Find a co-founder
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href={'/apps'} >
                    <a>
                      Apps
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href={'/the-nest'} >
                    <a>
                      The nest
                    </a>
                  </Link>
                </li>   
                <li>
                  <Link href={'/blog'} >
                    <a>
                      Blog
                    </a>
                  </Link>
                </li>               
                {me && (
                  <li>
                    <Link href={'/profile'} >
                      <a>
                        My Profile
                      </a>
                    </Link>
                  </li>
                )}
                <li>
                  <Link href={'/privacy-policy'} >
                    <a>
                      Privacy Policy
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href={'/cookie-policy'} >
                    <a>
                      Cookie Policy
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href={'/terms-of-service'} >
                    <a>
                      Terms of Service
                    </a>
                  </Link>
                </li>                                                                              
              </ul>
              {/* <a 
                href="https://www.producthunt.com/posts/starthawk?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-starthawk"
                target="_blank"
                className="footer__ph"
              >
                <img 
                  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=163205&theme=light"
                  alt="Starthawk - The perfect place to find a co-founder | Product Hunt Embed"
                  style={{width: "220px"}}
                />
              </a>  */}               
            </div>
          </div>
        </div>
        <div className="footer__lower">
          © {new Date().getFullYear()} Starthawk | Starthawk LTD
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {

};

export default Footer;