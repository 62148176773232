import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import './icon.scss';

const Icon = ({ iconUrl, modifierClass }) => (
  <i
    className={classNames('icon', {
      [`icon--${modifierClass}`]: modifierClass
    })}
    style={{ backgroundImage: `url(${iconUrl})` }}
  />
);

Icon.propTypes = {
  iconUrl: string.isRequired
};

export default Icon;