export default () => (
  <div
    style={{
      position: "fixed",
      backgroundColor: "white",
      backgroundImage: "url(/static/icons/spinner.svg)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "80px",
      backgroundPosition: "center center",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    }}
  />
);
