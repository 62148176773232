import React, { PureComponent } from 'react';
import NextLink from 'next/link';
import PropTypes from 'prop-types';

class Link extends PureComponent {
  render() {
    const { href, children } = this.props;
    return (
      <NextLink href={href} >
        {children}
      </NextLink>
    );
  }
}

Link.propTypes = {

};

export default Link;