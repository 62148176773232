import React, { PureComponent } from "react";
import enhanceWithClickOutside from "react-click-outside";

import ProfilePic from "../profile-pic/profile-pic";
import HeaderUserContent from "./header-user-content";

import "./header-user.scss";

class HeaderUser extends PureComponent {
  constructor() {
    super();
    this.state = {
      open: false,
    };
  }

  handleClickOutside = () => {
    if (this.state.open) {
      this.toggleProfile();
    }
  };

  toggleProfile = () => {
    this.setState((prevState) => {
      return {
        open: !prevState.open,
      };
    });
  };

  render() {
    const { me } = this.props;
    const { open } = this.state;

    return (
      <div className="header-user">
        <div className="header-user__inner">
          <div className="header-user__pic">
            <button onClick={this.toggleProfile}>
              <ProfilePic profilePic={me.profilePic} width={38} height={38} />
            </button>
          </div>
          {open && (
            <HeaderUserContent toggleProfile={this.toggleProfile} me={me} />
          )}
        </div>
      </div>
    );
  }
}

export default enhanceWithClickOutside(HeaderUser);
