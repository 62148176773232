import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Query, Mutation, ApolloConsumer } from "react-apollo";
import { Elements } from "react-stripe-elements";
import loadable from "@loadable/component";
import classNames from "classnames";
import Composer from "react-composer";
import gql from "graphql-tag";
import deepcopy from "deep-copy";
const CookieConsent = loadable(() => import("react-cookie-consent"));
const ChatOverlay = loadable(() => import("../chat-overlay/chat-overlay"));
const SignupForm = loadable(() =>
  import("../../components/signup-form/signup-form")
);

import Header from "../../components/header/header";
import Me from "../../components/me/me";
import Error from "../../components/error-message/error-message";
import Footer from "../../components/footer/footer";
import PagePopup from "../../components/page-popup/page-popup";
import RebrandMessage from "../../components/rebrand-message/rebrand-message";
import UpdateLastActive from "../../components/update-last-active/update-last-active";
import LoadingScreen from "../../components/loading-screen/loading-screen";

const PremiumFounderPopup = loadable(() =>
  import("../../components/premium-founder-popup/premium-founder-popup")
);
const pagesWithoutPadding = ["/"];

import { amountOfAllowedMessages } from "../../config";

import {
  LOCAL_UI_QUERY,
  LOCAL_MESSAGING_QUERY,
} from "../../apollo-state/graphql/queries";
import {
  LOCAL_TOGGLE_OVERLAY_MUTATION,
  LOCAL_TOGGLE_PREMIUM_COFOUNDER,
} from "../../apollo-state/graphql/mutations";

import "./page.scss";
import "./scroll.scss";

const UPDATE_LAST_ACTIVE = gql`
  mutation updateLastActive {
    updateLastActive {
      success
    }
  }
`;

const MESSAGES_QUERY = gql`
  query userMessages($skip: Int, $amountOfAllowedMessages: Int) {
    userMessages {
      conversations(
        last: $amountOfAllowedMessages
        skip: $skip
        orderBy: lastUpdated_ASC
      ) {
        users {
          id
          profilePic
          firstName
          hasValidCoFounderSubscription
        }
        id
        lastUpdated
      }
    }
  }
`;

const USER_CONVERSATIONS_SUBSCRIPTION = gql`
  subscription userConversations($token: String) {
    userConversations(token: $token) {
      users {
        id
        profilePic
        firstName
        hasValidCoFounderSubscription
      }
      id
      lastUpdated
    }
  }
`;

const portalNode = process.browser
  ? document.getElementById("page-portal")
  : null;

class Page extends Component {
  constructor() {
    super();
    this.state = {
      generalMessage: null,
      generalMessageActive: false,
      generalMessageType: "",
      paginationSkipAmount: 0,
      isOnLoadingScreen: false,
    };
  }

  setConvoPaginationSkipAmount = (paginationSkipAmount) => {
    this.setState({
      paginationSkipAmount,
    });
  };

  componentDidMount() {
    if (window.localStorage.token) {
      this.setState({
        isOnLoadingScreen: true,
      });
    }
  }

  render() {
    const {
      generalMessage,
      generalMessageActive,
      generalMessageType,
      paginationSkipAmount,
      isOnLoadingScreen,
    } = this.state;

    const {
      props: { query },
    } = this.props.children;

    const { children, route } = this.props;
    const noTopPagePadding =
      pagesWithoutPadding.filter((p) => p === route).length > 0;

    return (
      <div
        className={classNames("page", {
          "page--no-padding": noTopPagePadding,
        })}
      >
        <Composer
          components={[
            ({ render }) => (
              <Mutation mutation={LOCAL_TOGGLE_OVERLAY_MUTATION}>
                {render}
              </Mutation>
            ),
            ({ render }) => <Query query={LOCAL_UI_QUERY}>{render}</Query>,
            ({ render }) => (
              <Query query={LOCAL_MESSAGING_QUERY}>{render}</Query>
            ),
            ({ render }) => <ApolloConsumer>{render}</ApolloConsumer>,
            ({ render }) => (
              <Mutation
                mutation={LOCAL_TOGGLE_PREMIUM_COFOUNDER}
                variables={{ message: null }}
              >
                {render}
              </Mutation>
            ),
          ]}
        >
          {([
            toggleOverlay,
            uiState,
            messagingState,
            apolloConsumer,
            togglePremiumCofounder,
          ]) => {
            if (!uiState.data) return <span />;
            const {
              signupOverlayActive,
              chatOverlayActive,
              premiumCofounderOverlayMessage,
              premiumCofounderOverlayActive,
            } = uiState.data.ui;

            const headerProps = {
              toggleOverlay,
              uiState: uiState.data.ui,
              client: apolloConsumer,
            };

            return (
              <>
                {generalMessage && generalMessage.length > 0 && (
                  <div
                    className={classNames(
                      `page__general-msg page__general-msg--${generalMessageType}`,
                      {
                        "page__general-msg--active": generalMessageActive,
                      }
                    )}
                  >
                    <div className="page__general-msg__inner">
                      <strong>{generalMessage}</strong>
                    </div>
                  </div>
                )}
                <CookieConsent
                  location="bottom"
                  buttonText="OK"
                  cookieName="cookiesaccepted"
                  style={{ background: "#2B373B" }}
                  buttonStyle={{
                    color: "#4e503b",
                    fontSize: "13px",
                    borderRadius: "50px",
                    backgroundColor: "#f7dd78",
                    fontWeight: "bold",
                  }}
                  expires={365}
                >
                  This website uses cookies to enhance the user experience.
                </CookieConsent>
                <Me>
                  {({ data, loading }) => {
                    const me = data ? data.me : null;

                    if (isOnLoadingScreen && loading) {
                      return <LoadingScreen />;
                    }
                    if (me) {
                      return (
                        <>
                          <Query
                            query={MESSAGES_QUERY}
                            variables={{
                              skip: paginationSkipAmount,
                              amountOfAllowedMessages: amountOfAllowedMessages,
                            }}
                            fetchPolicy={"cache-and-network"}
                          >
                            {({
                              subscribeToMore,
                              loading,
                              error,
                              data,
                              refetch,
                            }) => {
                              if (error) return <Error error={error} />;
                              return (
                                <>
                                  <RebrandMessage
                                    loggedInHasReadRebrandMessage={
                                      me.hasReadRebrandMessage
                                    }
                                    isLoggedIn={true}
                                    apolloConsumer={apolloConsumer}
                                    query={query}
                                  />
                                  {data && data.userMessages && (
                                    <>
                                      <ChatOverlay
                                        messagingState={messagingState}
                                        apolloConsumer={apolloConsumer}
                                        query={query}
                                        refetch={refetch}
                                        fetchingPaginatedConvos={loading}
                                        subscribeToNewMessages={() =>
                                          subscribeToMore({
                                            document: USER_CONVERSATIONS_SUBSCRIPTION,
                                            variables: {
                                              token: localStorage.getItem(
                                                "token"
                                              ),
                                            },
                                            updateQuery: (
                                              prev,
                                              { subscriptionData }
                                            ) => {
                                              if (
                                                !subscriptionData.data ||
                                                paginationSkipAmount > 0
                                              )
                                                return prev;
                                              const newConvo =
                                                subscriptionData.data
                                                  .userConversations;
                                              const dataToReturn = deepcopy(
                                                prev
                                              );
                                              if (
                                                dataToReturn.userMessages
                                                  .conversations.length >=
                                                amountOfAllowedMessages
                                              ) {
                                                dataToReturn.userMessages.conversations.pop();
                                              }
                                              dataToReturn.userMessages.conversations.push(
                                                newConvo
                                              );
                                              return dataToReturn;
                                            },
                                          })
                                        }
                                        me={me}
                                        chatOverlayActive={chatOverlayActive}
                                        conversations={
                                          data.userMessages.conversations
                                        }
                                        setConvoPaginationSkipAmount={
                                          this.setConvoPaginationSkipAmount
                                        }
                                        paginationSkipAmount={
                                          paginationSkipAmount
                                        }
                                      />
                                    </>
                                  )}
                                  <Header
                                    {...headerProps}
                                    me={me}
                                    scrollEffect={noTopPagePadding}
                                  />
                                  {React.Children.map(children, (child) =>
                                    React.cloneElement(child, {
                                      me: me ? me : null,
                                      signupOverlayActive,
                                    })
                                  )}
                                  {premiumCofounderOverlayActive && (
                                    <Elements>
                                      <PagePopup
                                        togglePopup={togglePremiumCofounder}
                                        modifierClass="premium-founder"
                                        uiStateName="premiumCofounderOverlayActive"
                                      >
                                        <PremiumFounderPopup
                                          me={me}
                                          message={
                                            premiumCofounderOverlayMessage
                                          }
                                          apolloConsumer={apolloConsumer}
                                        />
                                      </PagePopup>
                                    </Elements>
                                  )}
                                  <Footer me={me} />
                                </>
                              );
                            }}
                          </Query>
                          <Mutation mutation={UPDATE_LAST_ACTIVE}>
                            {(updateLastActive) => (
                              <UpdateLastActive
                                updateLastActive={updateLastActive}
                              />
                            )}
                          </Mutation>
                        </>
                      );
                    }
                    return (
                      <>
                        <Header
                          {...headerProps}
                          scrollEffect={noTopPagePadding}
                        />
                        <RebrandMessage
                          loggedInHasReadRebrandMessage={false}
                          isLoggedIn={false}
                          apolloConsumer={apolloConsumer}
                          query={query}
                        />
                        {React.Children.map(children, (child) =>
                          React.cloneElement(child, {
                            me: me ? me : null,
                            toggleOverlay,
                          })
                        )}
                        <Footer me={null} />
                      </>
                    );
                  }}
                </Me>
                <div id="page-portal" />
                {portalNode &&
                  signupOverlayActive &&
                  ReactDOM.createPortal(
                    <PagePopup
                      togglePopup={toggleOverlay}
                      modifierClass="additional-details"
                    >
                      <SignupForm toggleOverlay={toggleOverlay} />
                    </PagePopup>,
                    portalNode
                  )}
              </>
            );
          }}
        </Composer>
      </div>
    );
  }
}

export { MESSAGES_QUERY };
export default Page;
