import React, { PureComponent } from "react";

class UpdateLastActive extends PureComponent {
  constructor() {
    super();
  }

  componentDidMount() {
    this.props.updateLastActive();
  }

  render() {
    return <span />;
  }
}

export default UpdateLastActive;
