import React, { PureComponent } from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import SigninForm from '../signin-form/sign-in-form';
import RequestResetPassword from '../request-reset-password/request-reset-password';
import './header-sign-in.scss';

class HeaderSignIn extends PureComponent {
  constructor() {
    super();
    this.state = {
      resetActive: false
    };
  }

  handleClickOutside = e => {
    e.stopPropagation();
    this.props.toggleSignIn();
  };

  toggleSignOrPass = () => {
    this.setState(
      prevState => ({
        resetActive: !prevState.resetActive
      })
    ); 
  };
  
  render() {
    const { resetActive } = this.state;

    return (
      <div className="header-sign-in">
        {resetActive ? (
          <RequestResetPassword toggleSignOrPass={this.toggleSignOrPass} />
        ) : (
          <SigninForm toggleSignOrPass={this.toggleSignOrPass} />
        )}
      </div>
    );
  }
}

export default enhanceWithClickOutside(HeaderSignIn);