import React, { PureComponent } from 'react';
import { debounce } from "debounce";
import gql from 'graphql-tag';

import RoundButton from '../round-button/round-button';
import Cta from '../cta/cta';

import getCookie from '../../utils/get-cookie';
import './rebrand-message.scss';

const HAS_BEEN_READ_REBRAND_MESSAGE_MUTATION = gql`
  mutation hasReadRebrandMessage {
    hasReadRebrandMessage {
      success
    }
  }
`;

class RebrandMessage extends PureComponent {
  constructor() {
    super();
    this.state = {
      showMessage: false,
      maxHeight: '100%'
    }
  }
  closeMessage = () => {
    this.setState({
      showMessage: false
    });
  };
  setCookie = () => {
    var CookieDate = new Date;
    CookieDate.setFullYear(CookieDate.getFullYear() + 1);

    document.cookie =
    `rebrand-message=true; expires=${CookieDate.toGMTString()}; path=/`;
  };
  setMaxHeight = () => {
    const { innerHeight } = window;
    this.setState({
      maxHeight: `${innerHeight}px`
    })
  }
  componentDidMount() {
    if (!this.props.query) return;
    const { rebrand } = this.props.query;
    
    window.addEventListener("resize", debounce(this.setMaxHeight, 50));
    this.setMaxHeight();

    const { loggedInHasReadRebrandMessage, isLoggedIn, apolloConsumer } = this.props;
    if (isLoggedIn && !loggedInHasReadRebrandMessage && rebrand) {
      this.setState({
        showMessage: true
      }, this.setCookie);
      
      apolloConsumer.mutate({
        mutation: HAS_BEEN_READ_REBRAND_MESSAGE_MUTATION
      });
    }
 
    const hasCookie = getCookie('rebrand-message');

    if (!isLoggedIn && !hasCookie && rebrand) {
      this.setState({
        showMessage: true
      }, this.setCookie);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", debounce(this.setMaxHeight, 50));
  }

  render() {
    const { showMessage, maxHeight } = this.state;

    return showMessage ? (
      <div className="rebrand-message" style={{ maxHeight }}>
        <button onClick={this.closeMessage} className="rebrand-message__underlay" />
        <div className="rebrand-message__inner">
          <div className="rebrand-message__content">
            <RoundButton onClick={this.closeMessage}/>
            <h3>Hi there!</h3>
            <p>It appears you have come through from our old domain (startbee.io). We have now rebranded to:</p>
            <img src="/static/logo.svg" alt="StartHawk" />
            <p>From now on you can use this domain (starthawk.io) to access the platform.</p>
            <Cta text="Got it" onClick={this.closeMessage} type="button" tag="button" />
          </div>
        </div>
      </div>
    ) : <span />
  }
}

export default RebrandMessage;