import React, { PureComponent } from "react";
import { Mutation, ApolloConsumer } from "react-apollo";
import gql from "graphql-tag";

import Cta from "../cta/cta";

const SIGN_OUT_MUTATION = gql`
  mutation SIGN_OUT_MUTATION {
    signOut {
      message
    }
  }
`;

class SignOut extends PureComponent {
  updateCache = (client) => {
    fetch("/logout").then(() => {
      localStorage.removeItem("token");
      client.resetStore();
      document.getElementsByTagName("body")[0].classList.remove("no-scroll");
    });
  };
  render() {
    return (
      <ApolloConsumer>
        {(client) => (
          <Mutation
            mutation={SIGN_OUT_MUTATION}
            update={() => this.updateCache(client)}
          >
            {(signout, { error, loading, called }) => {
              return (
                <Cta
                  disabled={loading}
                  loading={loading}
                  tag="button"
                  text="Sign out"
                  onClick={signout}
                />
              );
            }}
          </Mutation>
        )}
      </ApolloConsumer>
    );
  }
}

export default SignOut;
