const defaults = {
  ui: {
    __typename: "ui",
    signupOverlayActive: false,
    chatOverlayActive: false,
    mobileMenuOpen: false,
    premiumCofounderOverlayActive: false,
    premiumCofounderOverlayMessage: null,
    foundersGridAdditionalDetailsId: "",
    founderFiltersActive: false,
    profilePicUploadActive: false,
    promoCodeAppTileId: null,
  },
  messaging: {
    __typename: "messaging",
    convoOtherUserId: null,
    convoOtherUserProfilePic: null,
    convoOtherUserFirstName: null,
    activeConvoId: null,
  },
};

export default defaults;
