import { ApolloClient, InMemoryCache, HttpLink } from "apollo-boost";
import { setContext } from "apollo-link-context";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { split, ApolloLink } from "apollo-link";
import fetch from "isomorphic-unfetch";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { onError } from "apollo-link-error";
import { withClientState } from "apollo-link-state";
import { endpoint, prodEndpoint, WSendpoint, WSprodEndpoint } from "../config";
import defaults from "../apollo-state/graphql/default-state";
import Mutation from "../apollo-state/resolvers/mutation-resolvers";

let apolloClient = null;

const wsClient = process.browser
  ? new SubscriptionClient(
      process.env.NODE_ENV === "development" ? WSendpoint : WSprodEndpoint,
      {
        reconnect: true,
      }
    )
  : null;

// Polyfill fetch() on the server (used by apollo-client)
if (!process.browser) {
  global.fetch = fetch;
}

const dev = process.env.NODE_ENV !== "production";

function create(initialState, { getToken }) {
  const httpLink = new HttpLink({
    uri: dev ? endpoint : prodEndpoint, // Server URL (must be absolute)
    credentials: "include", // Additional fetch() options like `credentials` or `headers`
  });

  const authLink = setContext((_, { headers }) => {
    const headersToReturn = {
      headers: {
        ...headers,
      },
    };
    const tokenObj = getToken();
    if (tokenObj && tokenObj["startbeetoken"]) {
      headersToReturn.headers.authorization = `Bearer ${tokenObj["startbeetoken"]}`;
    }

    return headersToReturn;
  });

  const httpLinkWithAuthToken = authLink.concat(httpLink);

  const wsLink = process.browser ? new WebSocketLink(wsClient) : null;

  const link = process.browser
    ? split(
        ({ query }) => {
          const { kind, operation } = getMainDefinition(query);
          return kind === "OperationDefinition" && operation === "subscription";
        },
        wsLink,
        httpLinkWithAuthToken
      )
    : httpLinkWithAuthToken;

  const cache = new InMemoryCache().restore(initialState || {});
  return new ApolloClient({
    credentials: "include",
    ssrMode: !process.browser,
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          console.log(graphQLErrors);
        }
        if (networkError) {
          console.log(networkError);
        }
      }),
      withClientState({
        defaults, // default state
        resolvers: {
          Mutation, // mutations
        },
        cache,
      }),
      link,
    ]),
    cache,
  });
}

export default function initApollo(initialState, options) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(initialState, options);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, options);
  }

  return apolloClient;
}

export { wsClient };
