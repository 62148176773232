import React, { PureComponent } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import Form from '../form/form';
import TextInput from '../text-input/text-input';
import Error from '../error-message/error-message';
import Cta from '../cta/cta';

const REQUEST_RESET_PASSWORD_MUTATION = gql`
  mutation REQUEST_RESET_PASSWORD_MUTATION(
      $email: String!
    ) {
      requestResetPassword(
        email: $email
      ) {
        message
      }
    }
`;

class RequestResetPassword extends PureComponent {
  constructor() {
    super();
    this.state = {
      email: ''
    };
  }

  updateValue = ({ forLabel, value }) => {
    this.setState({ [forLabel]: value });
  };

  requestResetPre = reset => {
    if (this.state.email.length === 0) {
      const realEmailValue = document.getElementById('email').value;
      this.setState({
        email: realEmailValue
      }, () => reset())
    } else {
      reset();
    }
  };
  
  render() {
    const { email } = this.state;
    const { toggleSignOrPass } = this.props;

    return (
      <Mutation
        mutation={REQUEST_RESET_PASSWORD_MUTATION}
        variables={{
          email
        }}
      >
        {(reset, { error, loading, called }) => {
          return (
            <Form onSubmit={() => this.requestResetPre(reset)} method="post" loading={loading} forceUpdateState autoCompleteDisabled>
              <Error error={error} />
              {!error && !loading && called && <div style={{ paddingBottom: '20px' }}>Success, check your email for a reset link.</div>}
              <TextInput
                label="Email"
                forLabel="email"
                type="text"
                updateValue={this.updateValue}
                value={email}
              />
              <Cta tag="button" text="Reset password" />
              <Cta modifierClass="forgot-pass" tag="button" text="Back to sign in" onClick={toggleSignOrPass} basic />
            </Form>
          )
        }}
      </Mutation>
    );
  }
};

export default RequestResetPassword;
