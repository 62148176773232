import React, { PureComponent } from 'react';
import Link from 'next/link';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import enhanceWithClickOutside from 'react-click-outside';

import SignOut from '../sign-out/sign-out';
import './header-user.scss';

class HeaderUserContent extends PureComponent {
  render() {
    const { me, toggleProfile } = this.props;

    return (
      <ReactCSSTransitionGroup
        transitionName="overlay"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}
      >   
        <>
          <div className="header-user__popup">
            <div className="pattern" />
            <div className="header-user__content">
              <div className="header-user__popup__name">
                <strong>{`${me.firstName} ${me.lastName}`}</strong>
              </div>
              <div className="header-user__popup__profile">
                <Link href={'/profile'} >
                  <span onClick={toggleProfile}>
                    <a>View my profile</a>
                  </span>
                </Link> 
              </div>            
              <div className="header-user__popup__sign-out">
                <SignOut />
              </div>
            </div>
          </div>          
        </>
      </ReactCSSTransitionGroup>
    );
  }
};

export default enhanceWithClickOutside(HeaderUserContent);
