import React, { PureComponent } from 'react';
import Router from 'next/router';
import NProgress from 'nprogress';
import NextLink from 'next/link';
import classNames from 'classnames';
import { Mutation, Query } from 'react-apollo';
import Composer from 'react-composer';
import { HamburgerButton } from 'react-hamburger-button';
import { debounce } from "debounce";

import Link from '../link/link';
import * as gtag from '../../lib/gtag';
import HeaderSignIn from './header-sign-in';
import HeaderUser from './header-user';
import Cta from '../cta/cta';

import toggleBodyScroll from '../../utils/toggle-body-scroll';
import { uiPopupStateItems } from '../../apollo-state/resolvers/mutation-resolvers';

import {
  LOCAL_TOGGLE_OVERLAY_MUTATION,
  LOCAL_TOGGLE_MESSAGES,
  LOCAL_TOGGLE_MOBILE_MENU,
  LOCAL_TOGGLE_PREMIUM_COFOUNDER
} from '../../apollo-state/graphql/mutations';

import { 
  LOCAL_UI_QUERY
} from '../../apollo-state/graphql/queries';

import './header.scss';

class Header extends PureComponent {
  constructor() {
    super();
    this.state = {
      signInOpen: false,
      headerScrolled: false
    };
  }

  componentDidMount() {
    this.setHeaderScrolled();
    window.addEventListener("scroll", debounce(this.setHeaderScrolled, 10));
    
    Router.onRouteChangeStart = () => {
      NProgress.start();
      const { ui } = this.props.client.readQuery({
        query: LOCAL_UI_QUERY
      });

      const uiPopupStateItemsObject = {};
      uiPopupStateItems.forEach(item => uiPopupStateItemsObject[item] = false);

      const data = {
        data: {
          ui: {
            ...ui,
            ...uiPopupStateItemsObject
          }
        }
      };

      toggleBodyScroll("", "", "OFF");
      this.props.client.writeData(data); 
    };
    
    Router.onRouteChangeComplete = url => {
      gtag.pageview(url);
      NProgress.done();

    }
      
    Router.onRouteChangeError = () => {
      NProgress.done();
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", debounce(this.setHeaderScrolled, 10));
  }

  setHeaderScrolled = () => {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

    this.setState({
      headerScrolled: top > 0
    })
  }

  toggleMobileMenu = newState => {
    this.setState(
      prevState => {
        return {
          mobileMenuOpen: newState === false ? newState : !prevState.mobileMenuOpen
        };
      }
    ); 
  };

  toggleSignIn = () => {
    this.setState(
      prevState => {
        return {
          signInOpen: !prevState.signInOpen
        };
      }
    ); 
  }

  render() {
    const { signInOpen, headerScrolled } = this.state;
    const { me, scrollEffect } = this.props;

    return (
      <Composer
        components={[
          ({render}) => <Mutation mutation={LOCAL_TOGGLE_MESSAGES}>{render}</Mutation>,
          ({render}) => <Mutation mutation={LOCAL_TOGGLE_OVERLAY_MUTATION}>{render}</Mutation>,
          ({render}) => <Mutation mutation={LOCAL_TOGGLE_MOBILE_MENU}>{render}</Mutation>,
          ({render}) => <Query query={LOCAL_UI_QUERY}>{render}</Query>,
          ({render}) => <Mutation mutation={LOCAL_TOGGLE_PREMIUM_COFOUNDER} variables={{ message: null }}>{render}</Mutation>
        ]}
      >
        {([toggleMessages, toggleOverlay, toggleMobileMenu, uiState, togglePremiumCofounder]) => (
          <div className={classNames("header", {
            "header--no-bg": scrollEffect && !headerScrolled && !uiState.data.ui.chatOverlayActive,
            'header--mobile-menu-open': uiState.data.ui.mobileMenuOpen
          })}>
            <div className="header__inner">
              <div className="header__logo">
                <Link href={me ? '/find-a-co-founder' : '/'} >
                  <a>
                    <img className="header__logo__img--desktop" src={scrollEffect && !headerScrolled && !uiState.data.ui.chatOverlayActive && !uiState.data.ui.mobileMenuOpen ? "/static/logo-white.svg" : "/static/logo.svg"} alt="StartHawk" />
                    <img className="header__logo__img--mobile" src={scrollEffect && !headerScrolled && !uiState.data.ui.chatOverlayActive && !uiState.data.ui.mobileMenuOpen ? "/static/logo-no-text-white.svg" : "/static/logo-no-text.svg"} alt="StartHawk" />
                  </a>
                </Link>
              </div>
              <div className="header__menu-button-mobile">
                <HamburgerButton
                  open={uiState.data.ui.mobileMenuOpen}
                  onClick={toggleMobileMenu}
                  width={22}
                  height={16}
                  strokeWidth={1}
                  color='#5f5f5f'
                  animationDuration={0.2}
                />
              </div>
              {me ? (
                <>
                  <div className="header__header-user-mobile">
                    <HeaderUser me={me} />
                  </div>
                 <button className={classNames("header__messages-button header__messages-button--mobile", {
                    'header__messages-button--active': uiState.data.ui.chatOverlayActive
                 })} onClick={() => {toggleMessages(); this.toggleMobileMenu(false);}} />
                </>
              ) : (
                <>
                  <div className="header__signed-out-cta">
                    <div className="header__signed-out-cta__hide header__signed-out-cta--sign-in">
                      <Cta basic text="Sign in" onClick={this.toggleSignIn} type="submit" tag="button" args={{ type: 'signin' }} />
                    </div>
                    {signInOpen && (
                      <HeaderSignIn toggleSignIn={this.toggleSignIn} />
                    )}
                  </div>
                  <div className="header__signed-out-cta header__signed-out-cta--sign-up">
                    <Cta modifierClass={scrollEffect && !headerScrolled && !uiState.data.ui.chatOverlayActive && !uiState.data.ui.mobileMenuOpen ? "white-outline" : ""} text="Sign up free" onClick={toggleOverlay} type="submit" tag="button" args={{ type: 'signup' }} />
                  </div>                    
                </>        
              )}
              <div className={classNames('header__menu', {
                'header__menu--open': uiState.data.ui.mobileMenuOpen
              })}>
                <nav>
                  <ul>
                    {me && !me.hasValidCoFounderSubscription && <li className="header__menu__item">
                      <Cta modifierClass="premium" text="Upgrade" onClick={togglePremiumCofounder} type="button" tag="button" />
                    </li>}                 
                    <li className="header__menu__item">
                      <Link href={'/blog'}>
                        <a className="header__menu__item__a">Blog</a>
                      </Link>
                    </li>               
                    <li className="header__menu__item">
                      <Link href={'/the-nest'}>
                        <a className="header__menu__item__a">The nest</a>
                      </Link>
                    </li>                    
                    <li className="header__menu__item">
                      <Link href={'/apps'}>
                        <a className="header__menu__item__a">Apps</a>
                      </Link>
                    </li>                
                    <li className="header__menu__item">
                      <Link href={'/find-a-co-founder'}>
                        <a className="header__menu__item__a">Find a co-founder</a>
                      </Link>
                    </li>                     
                    {me && (
                      <li className="header__menu__item header__menu__item--profile">
                        <NextLink href={'/profile'} >
                          <a className="header__menu__item__a">Profile</a>
                        </NextLink>
                      </li>
                    )}                                     
                    {me ? (
                        <>
                          <li className="header__menu__item header__menu__item--messages">
                            <button
                              title={uiState.data.ui.chatOverlayActive ? 'Close messages' : 'Open messages'}
                              className={classNames("header__messages-button header__messages-button--desktop header__menu__item__a", {
                                'header__messages-button--active': uiState.data.ui.chatOverlayActive
                              })} onClick={toggleMessages} />
                          </li>
                          <li className="header__menu__item header__menu__item--header-user">
                            <HeaderUser me={me} />
                          </li>
                        </>
                        ) : (
                      <>
                        <li className="header__menu__item header__menu__item--sign-up">
                          <Cta modifierClass={scrollEffect && !headerScrolled && !uiState.data.ui.chatOverlayActive ? "white-outline" : ""} text="Sign up free" onClick={toggleOverlay} type="submit" tag="button" args={{ type: 'signup' }} />
                        </li>                      
                        <li className="header__menu__item header__menu__item--sign-in">
                          <Cta basic text="Sign in" onClick={this.toggleSignIn} type="submit" tag="button" args={{ type: 'signin' }} />
                        </li>
                      </>
                    )}
                  </ul>
                  <Mutation
                    mutation={LOCAL_TOGGLE_MOBILE_MENU}
                    variables={{ value: !uiState.data.ui.mobileMenuOpen }}
                  >
                    {closeMobileMenu => (
                      <button className="header__menu-close" onClick={closeMobileMenu}>Close menu</button>
                    )}
                  </Mutation>
                </nav>
              </div>
            </div>
          </div>
        )}
      </Composer>
    );
  }
}

Header.defaultProps = {
  me: null,
  totalUnreadMessages: 0
};

export default Header;
